// Javascript goes here
import "bootstrap";
import IMask from "imask";
import { Offcanvas } from "bootstrap";
import { Modal } from "bootstrap";
const offcanvasNav = document.getElementById("offcanvasNav");
const modal = document.getElementById("ourWorkModal");
const bsModal = new Modal(modal);
const bsOffcanvas = new Offcanvas(offcanvasNav);
const hamburger = document.querySelector(".hamburger");

hamburger.addEventListener("click", function () {
  hamburger.classList.toggle("is-active");
});

document.addEventListener("DOMContentLoaded", function () {
  IMask(document.getElementById("phoneInput"), {
    mask: "(000)000-0000",
  });

  // close offcanvas when click on a link
  document.querySelectorAll(".offcanvas .accordion-link").forEach((link) => {
    console.log(link);
    link.addEventListener("click", () => {
      bsOffcanvas.hide();
      hamburger.classList.remove("is-active");
      offcanvasNav.addEventListener("hidden.bs.offcanvas", () => {
        // find anchor href and scroll to it
        const href = link.getAttribute("href");
        const target = document.querySelector(href);
        target.scrollIntoView({ behavior: "smooth" });
      });
    });
  });
  // close offcanvas when click on a link
  document.querySelectorAll(".offcanvas .nav-link").forEach((link) => {
    console.log(link);
    link.addEventListener("click", () => {
      bsOffcanvas.hide();
      hamburger.classList.remove("is-active");
      offcanvasNav.addEventListener("hidden.bs.offcanvas", () => {
        // find anchor href and scroll to it
        const href = link.getAttribute("href");
        const target = document.querySelector(href);
        target.scrollIntoView({ behavior: "smooth" });
      });
    });
  });

  // trigger modal
  if (document.getElementById("ourWorkModal")) {
    document.querySelectorAll(".modal-trigger").forEach((trigger) => {
      trigger.addEventListener("click", () => {
        // get image url
        const imageUrl = trigger.getAttribute("src");
        console.log(imageUrl);

        if (document.getElementById("modalImage")) {
          document.getElementById("modalImage").setAttribute("src", imageUrl);
        }
        bsModal.show();
      });
    });
  }
});
